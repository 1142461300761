import React, { useEffect, useState, useRef } from "react";
import { IconArrowStem21 } from "../../icons/IconArrowStem21";
import axios from 'axios';

export const LatestNews = ({ maxArts = 20, style='full', startat=1 }) => {
    const [newsArt, setNewsArt] = useState([]);
    function decodeHtmlEntities(str) {
      var textArea = document.createElement('textarea');
      textArea.innerHTML = str;
      return textArea.value;
    }

    async function fetchMedia(mediaId) {
      let media = '';
      if (mediaId) {
        try {
            const response = await axios.get(`https://news.cricket8.com/wp-json/wp/v2/media/${mediaId}`);
            media = response.data.source_url; 
        } catch (error) {
            //console.log('Error fetching media:', error);
        }
      } 
      return media
    };

    async function fetchNews(startat) {
      try {
          const res = await fetch(`${process.env.API_URL}/newsarticles/2/${startat}`, {
              method: 'GET',
              headers: {
                  'Content-Type': 'application/json',
                  'x-api-key': process.env.API_KEY
              },
          });
  
          if (res.status === 200) {
              const data = await res.json();
              const newsArticles = await Promise.all(data.map(async news => {
                  let mediaId = news.featured_media;
                  let mediasrc = await fetchMedia(mediaId);
                  let title = decodeHtmlEntities(news.title.rendered);
                  const dateObject = new Date(news.date);
                  const formattedDate = dateObject.toLocaleDateString('en-US', { day: '2-digit', month: 'long' });
                  let dte = formattedDate;
  
                  return { 
                      title: title, 
                      media: mediasrc, 
                      date: dte, 
                      link: news.link, 
                      slug: news.slug, 
                      id: news.id 
                  };
              }));
              setNewsArt(newsArticles);
          } else {
              //console.log('Failed to fetch news:', res.status);
          }
      } catch (error) {
          //console.log('Error:', error);
      }
    }
  

    useEffect(() => {
        fetchNews(startat);
    }, [startat]);
          
    return (
      <>
      {style==='summary' ? (
        <>
        {newsArt.length>0 ? (
        <div className='w-full md:flex'>
          <div className='w-full md:w-[50%] md:mr-[25px]'>
            <a href={`/news?slug=${newsArt[0].slug}&id=${newsArt[0].id}`} target='_blank'>
              <div className='w-full flex'>
                <div className='bg-[#d2ff00] font-anton text-[#1b062d] text-[14px] px-[10px] py-[10px]'>NEWS</div>
                <div className='flex-grow bg-[#fff] bg-opacity-15 font-chakra font-normal text-white text-right text-[14px] px-[10px] py-[10px]'>
                  {newsArt[0].date.toUpperCase()}
                </div>
              </div>
              <div className='my-[2px]'>
                <img src={newsArt[0].media} className='w-[100%] h-[350px] object-cover' />
              </div>
              <div className='bg-[#fff] relative'>
                <div className='py-[20px] pl-[15px] pr-[35px] font-anton text-[22px] text-[#1b062f] truncate'>
                  {newsArt[0].title}
                </div>
                <div className='absolute bottom-0 right-0 bg-[#d2ff00] h-[40px] w-[40px] border-l border-t border-solid border-[#1b062f] pt-[10px] pl-[10px]'>
                  <IconArrowStem21 className="!w-[22px] !h-[14px]" color="#1B062F" />
                </div>
              </div>
            </a>
          </div>
          <div className='w-full md:w-[50%] md:ml-[25px] mt-[30px] md:mt-0'>
            <div>
              <a href={`/news?slug=${newsArt[1].slug}&id=${newsArt[1].id}`} target='_blank'>
                <div className='w-full flex'>
                  <div className='bg-[#d2ff00] font-anton text-[#1b062d] text-[14px] px-[10px] py-[10px]'>NEWS</div>
                  <div className='flex-grow bg-[#fff] bg-opacity-15 font-chakra font-normal text-white text-right text-[14px] px-[10px] py-[10px]'>
                      {newsArt[1].date.toUpperCase()}
                  </div>
                </div>
                <div className='w-full flex relative h-[100px] my-[2px]'>
                  <div className='w-[100px] h-[100px]'>
                    <img src={newsArt[1].media} className='w-[100px] h-[100px] object-cover' />
                  </div>
                  <div className='flex-grow bg-white h-[100px]'>
                    <div className='pt-[25px] pl-[15px] mr-[20px] font-anton text-[22px] text-[#1b062f]'>
                      {newsArt[1].title}
                    </div>
                  </div>
                  <div className='absolute bottom-0 right-0 bg-[#d2ff00] h-[40px] w-[40px] border-l border-t border-solid border-[#1b062f] pt-[10px] pl-[10px]'>
                    <IconArrowStem21 className="!w-[22px] !h-[14px]" color="#1B062F" />
                  </div>
                </div>
              </a>
            </div>
            <div className='mt-[30px]'>
              <a href={`/news?slug=${newsArt[2].slug}&id=${newsArt[2].id}`} target='_blank'>
                <div className='w-full flex'>
                  <div className='bg-[#d2ff00] font-anton text-[#1b062d] text-[14px] px-[10px] py-[10px]'>NEWS</div>
                  <div className='flex-grow bg-[#fff] bg-opacity-15 font-chakra font-normal text-white text-right text-[14px] px-[10px] py-[10px]'>
                      {newsArt[2].date.toUpperCase()}
                  </div>
                </div>
                <div className='w-full flex relative h-[100px] my-[2px]'>
                  <div className='w-[100px] h-[100px]'>
                    <img src={newsArt[2].media} className='w-[100px] h-[100px] object-cover' />
                  </div>
                  <div className='flex-grow bg-white h-[100px]'>
                    <div className='pt-[25px] pl-[15px] mr-[20px] font-anton text-[22px] text-[#1b062f]'>
                      {newsArt[2].title}
                    </div>
                  </div>
                  <div className='absolute bottom-0 right-0 bg-[#d2ff00] h-[40px] w-[40px] border-l border-t border-solid border-[#1b062f] pt-[10px] pl-[10px]'>
                    <IconArrowStem21 className="!w-[22px] !h-[14px]" color="#1B062F" />
                  </div>
                </div>
              </a>
            </div>
            <div className='mt-[30px]'>
                <a href="/news">
                  <div className='h-[50px] bg-[#d2ff00] w-full text-anton text-[16px] text-[#1b062d] text-center pt-[17px]'>
                    xxALL NEWS &amp; ANALYSIS
                  </div>
                </a>
            </div>
          </div>
        </div>
        ) : ( null )}
        </>
      ) : (
        <div>
          <div className='md:flex md:flex-wrap gap-4'>
              {newsArt.slice(0, maxArts).map((news,index) => (
                  <div className='w-full md:w-[49%] mb-[20px] md:mb-0 relative'>
                      <a href={`/news?slug=${news.slug}&id=${news.id}`} target='_blank'>
                      <img src={news.media} className='w-[100%] h-[350px] object-cover' />
                      <div className='absolute bottom-0 md:top-0 left-0 w-full h-full flex flex-col justify-between'>
                          <div className='absolute bottom-[31px] md:top-0 left-0 w-[50px] h-[30px] bg-white anton text-black text-14px pt-[8px] pl-[10px]'>
                              NEWS
                          </div>
                          <div className='absolute bottom-[31px] md:top-0 left-[50px] h-[30px] bg-[#1b062f] anton text-white text-14px pt-[8px] pl-[10px] pr-[10px]'>
                              {news.date}
                          </div>
                          <div className='absolute w-[90%] h-[30px] md:h-[38px] bottom-[1px] md:top-[30px] text-[16px] md:text-[20px] left-0 text-black p-2 bg-[#d2ff00] truncate'>
                              {news.title}    
                          </div>
                      </div>
                      </a>
                  </div>
              ))}
          </div>
          <div className='w-full flex justify-center mt-[30px]'>
            <a href={`/news?startat=${parseInt(startat)+10}`}>
              <div className='mt-[15px] bg-[#d2ff00] w-full md:w-[600px] h-[50px] text-[#1b062f] pt-[15px] font-anton text-[18px] text-center cursor-pointer'>
                  LOAD MORE
              </div>
            </a>
          </div>

        </div>
      )}
      </>
  );
};
