import React, {useEffect, useState} from "react";
import {LatestNews} from "../LatestNews";
import {useLocation, useNavigate} from 'react-router-dom';
import ShareModal from "../ShareModal";
import axios from 'axios';

export const News = () => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);
    const navigate = useNavigate();
    const goBack = () => navigate('/news');

    const xlocation = useLocation();
    const url = window.location.href;
    const queryParams = new URLSearchParams(xlocation.search);
    let art = queryParams.get("art");
    let artId = queryParams.get("id");
    let startat = queryParams.get("startat");
    if (startat === null) {
        startat = 1;
    }
    const slug = queryParams.get("slug");
    if (slug === null) {
        artId = slug;
    }
    const [title, setTitle] = useState('ARTICLES');
    const [article, setArticle] = useState(null);

    async function fetchMedia(mediaId) {
        let media = '';
        if (mediaId) {
            try {
                const response = await axios.get(`https://news.cricket8.com/wp-json/wp/v2/media/${mediaId}`);
                media = response.data.source_url;
            } catch (error) {
                //console.log('Error fetching media:', error);
            }
        }
        return media
    };

    async function fetchNewsArt(id, isSlug = false) {
        try {
            let uri = process.env.API_URL + "/news/article/" + id;
            if (isSlug) {
                uri = process.env.API_URL + "/news/articlebyslug/" + id;
            }
            const res = await fetch(uri, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': process.env.API_KEY
                },
            });
            if (res.status === 200) {
                const data = await res.json();
                artId = data.id;
                setTitle(decodeURI(data.title.rendered));
                document.title = decodeURI(data.title.rendered);
                const metaDescription = document.querySelector('meta[name="description"]');
                if (metaDescription) {
                    metaDescription.setAttribute('content', decodeURI(data.excerpt.rendered));
                } else {
                    const newMetaDescription = document.createElement('meta');
                    newMetaDescription.name = 'description';
                    newMetaDescription.content = decodeURI(data.content.rendered);
                    document.head.appendChild(newMetaDescription);
                }
                const ogImage = document.querySelector('meta[property="og:image"]');
                let mediasrc = await fetchMedia(data.featured_media);

                const imageUrl = mediasrc;
                data.featuredImg = mediasrc;
                if (ogImage) {
                    ogImage.setAttribute('content', imageUrl);
                } else {
                    const newOgImage = document.createElement('meta');
                    newOgImage.setAttribute('property', 'og:image');
                    newOgImage.content = imageUrl;
                    document.head.appendChild(newOgImage);
                }
                const regex = /<a href="https:\/\/news\.cricket8\.com\/(.*?)\/">/g;
                const updatedContent = data.content.rendered.replace(regex, (match, slug) => {
                    return `<a href="/news?slug=${slug}&id=null">`;
                });
                data.content.rendered = updatedContent;
                data.modified = data.modified.replace("T", " AT ");
                setArticle(data);
            } else {
                //console.error('Failed to fetch scorecard:', res.status);
            }
        } catch (error) {
            //console.log('Network error:', error);
        }

    }

    useEffect(() => {
        if (artId !== null) {
            fetchNewsArt(artId);
        } else if (slug !== null) {
            fetchNewsArt(slug, true);
        }
        const updatePageTitle = () => {
            document.title = "ARTICLES | Cricket8";
        };
        updatePageTitle();
        return () => {
        };
    }, []);
    return (
        <div id='content' className='min-h-[75vh] mt-[0px] md:mt-[40px]'>
            {artId === null ? (null) : (
                <div className='flex pb-[10px] cursor-pointer' onClick={goBack}>
                    <div className='h-6 bg-[#d2ff00] text-[#1b062f] pt-[4px] px-[6px]'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                            <path fill="currentColor" d="m14 18l-6-6l6-6l1.4 1.4l-4.6 4.6l4.6 4.6z"/>
                        </svg>
                    </div>
                    <div
                        className='h-6 font-anton text-[#1b062f] text-[14px] px-[8px] pt-[6px] ml-[3px] bg-[#d2ff00]'>BACK
                    </div>
                </div>
            )}

            <div className='flex w-full'>
                <div className='flex-grow'>
                    <h1 className='text-[30px] md:text-[40px]'>{title}</h1>
                </div>
                {artId !== null ? (
                    <div>
                        <button onClick={openModal} className='share-button'>
                            <div className='flex'>
                                <div className='btn'>Share</div>
                                <div
                                    className='bg-[#d2ff00] text-[18px] border border-1 border-solid border-[#1b062f] p-[10px] pt-[15px] cursor-pointer text-[#1b062f]'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                                         viewBox="0 0 24 24">
                                        <path fill="currentColor"
                                              d="M17 22q-1.25 0-2.125-.875T14 19q0-.15.075-.7L7.05 14.2q-.4.375-.925.588T5 15q-1.25 0-2.125-.875T2 12t.875-2.125T5 9q.6 0 1.125.213t.925.587l7.025-4.1q-.05-.175-.062-.337T14 5q0-1.25.875-2.125T17 2t2.125.875T20 5t-.875 2.125T17 8q-.6 0-1.125-.213T14.95 7.2l-7.025 4.1q.05.175.063.338T8 12t-.012.363t-.063.337l7.025 4.1q.4-.375.925-.587T17 16q1.25 0 2.125.875T20 19t-.875 2.125T17 22"/>
                                    </svg>
                                </div>
                            </div>
                        </button>
                        <ShareModal isOpen={isModalOpen} onRequestClose={closeModal} url={url}/>
                    </div>
                ) : (null)}
            </div>

            <div className='mt-[40px]'>
                {artId === null ? (
                    <LatestNews maxArts={10} startat={startat}/>
                ) : (
                    <>
                        {article !== null ? (
                            <div className='blogpost normal-case'>
                                <div className='flex'>
                                    <div className='flex-grow font-chakra text-[12px] text-white'>
                                        {/* <div className='uppercase'>{article.yoast_head_json.twitter_misc["Est. reading time"]} read</div> */}
                                        <div>LAST UPDATED: {article.modified}</div>
                                    </div>
                                    <div
                                        className='text-right bg-white border border-solid border-1 border-[#d2ff00] text-[#1b062f] font-anton h-[25px] text-[18px] px-[10px] pt-[2px]'>
                                        NEWS
                                    </div>
                                </div>
                                <div className='bg-white'>
                                    <div className='mt-[30px]'>
                                        <img src={article.featuredImg} className='max-w-full'/>
                                    </div>
                                    <div className='p-[10px]'>
                                        <div dangerouslySetInnerHTML={{__html: article.content.rendered}}/>
                                    </div>
                                </div>

                            </div>
                        ) : (null)}
                    </>
                )}
            </div>
        </div>
    )
};
export default News;
